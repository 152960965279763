import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import ProfileDetails from "../components/profile/ProfileDetails";
import useSWR from "swr";
import { fetcher } from "../helpers/axios";
import { Post } from "../components/posts";
import { Row, Col } from "react-bootstrap";

function Profile() {
  const { profileId } = useParams();

  const user = useSWR(`/user/${profileId}/`, fetcher);

  const [postsData, setPostsData] = useState([]);
  const [postsNext, setPostsNext] = useState(`/post/${profileId}/userposts`);
  let x = false;
  let y = false;
  let next_data = "";

  const posts = useSWR(postsNext, fetcher, {
    refreshInterval: 20000,
  });

  if (typeof posts.data !== "undefined") {
    if (typeof posts.data.results !== "undefined") {
      next_data = postsData.concat(posts.data.results);
      x = true;
      if (posts.data.next) {
        y = true;
      }
    }
  }

  return (
    <Layout hasNavigationBack>
      <Row className='justify-content-evenly'>
        <Col sm={9}>
          <ProfileDetails user={user.data} />
          <div>
            <Row className='my-4'>
              {x &&
                next_data.map((post, index) => (
                  <Post key={index} post={post} refresh={posts.mutate} />
                ))}
              {y && (
                <button
                  onClick={() => {
                    setPostsData(next_data);
                    setPostsNext(posts.data.next);
                    posts.mutate();
                  }}
                >
                  Load More
                </button>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Profile;

import React, { useState, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axiosService from "../../helpers/axios";
import { getUser } from "../../hooks/user.actions";
import { Context } from "../Layout";
//import { useDropzone } from "react-dropzone";
import VideoUpload from "./VideoUpload";

function CreatePost(props) {
  const { refresh } = props;
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    author: "",
    body: "",
  });
  //const [video, setVideo] = useState(null);
  //const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [files, setFiles] = useState("");

  const { setToaster } = useContext(Context);

  const user = getUser();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const createPostForm = event.currentTarget;

    if (createPostForm.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    const data = {
      author: user.id,
      body: form.body,
    };

    const formData = new FormData();

    // Checking for null values in the form and removing it.

    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });

    if (files) {
      formData.append("video", files);
    }

    console.log(formData);

    axiosService
      .post("/post/", formData)
      .then(() => {
        handleClose();
        setToaster({
          type: "success",
          message: "Post created 🚀",
          show: true,
          title: "Post Success",
        });
        setForm({});
        refresh();
      })
      .catch(() => {
        setToaster({
          type: "danger",
          message: "An error occurred.",
          show: true,
          title: "Post Error",
        });
      });
  };

  return (
    <>
      <Form.Group className='my-3 w-75'>
        <Form.Control
          className='py-2 rounded-pill border-primary text-primary'
          data-testid='show-modal-form'
          type='text'
          placeholder='Write a post'
          onClick={handleShow}
        />
      </Form.Group>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='border-0'>
          <Modal.Title>Create Post</Modal.Title>
        </Modal.Header>
        <Modal.Body className='border-0'>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            data-testid='create-post-form'
          >
            <Form.Group className='mb-3 d-flex flex-column'>
              <VideoUpload files={files} setFiles={setFiles} post='' />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Control
                name='body'
                data-testid='post-body-field'
                value={form.body}
                onChange={(e) => setForm({ ...form, body: e.target.value })}
                as='textarea'
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={handleSubmit}
            disabled={!form.body}
            data-testid='create-post-submit'
          >
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreatePost;

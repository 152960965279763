import React from "react";
import { useDropzone } from "react-dropzone";
import mainLogo from "./logo.png";

function VideoUpload(props) {
  //const [files, setFiles] = useState([]);
  const { files, setFiles, post } = props;
  //let thumbs = "";
  //var first = 0;
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      //"image/*": [],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/png": [".png"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      let url = URL.createObjectURL(acceptedFiles[0]);
      acceptedFiles[0].preview = url;
      //first = 1;
      setFiles(acceptedFiles[0]);

      /*onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },*/
    },
  });

  /*
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));*/

  /*
  if (files.type === "video/mp4") {
    thumbs = (
      <div style={thumb} key={files.name}>
        <div style={thumbInner}>
          <iframe
            src={files.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(files.preview);
            }}
          />
        </div>
      </div>
    );
  } else {
    thumbs = (
      <div style={thumb} key={files.name}>
        <div style={thumbInner}>
          <img
            src={files.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(files.preview);
            }}
          />
        </div>
      </div>
    );
    */
  //if (first !== 0) {
  /* if (files.path.split(".").pop() === "mp4") {
    console.log("1");
    thumbs = (
      <div className='video-responsive'>
        <iframe
          className='video-responsive iframe'
          src={files.preview}
          style={img}
          allowFullScreen
          allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          //Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(files.preview);
          }}
        />
      </div>
    );
  } else {
    console.log("2");
    thumbs = (
      <div style={thumb} key={files.name}>
        <div style={thumbInner}>
          <img
            src={files.preview}
            style={img}
            //Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(files.preview);
            }}
          />
        </div>
      </div>
    );
  }*/
  //}

  return (
    <section className='container'>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Click to select files</p>
      </div>
      {files.path ? (
        files.path?.split(".").pop() === "mp4" ? (
          <video
            controls
            muted
            className='img-thumbnail img-fluid'
            src={files.preview}
            allowFullScreen
            //Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(files.preview);
            }}
          />
        ) : (
          <div key={files.name}>
            <img
              className='img-thumbnail img-fluid'
              src={files.preview}
              //Revoke data uri after image is loaded
              onLoad={() => {
                URL.revokeObjectURL(files.preview);
              }}
            />
          </div>
        )
      ) : post.video ? (
        post.video?.split(".").pop() === "mp4" ? (
          <video
            controls
            muted
            className='img-thumbnail img-fluid'
            src={post.video}
            allowFullScreen
          />
        ) : (
          <div key={post.video.name}>
            <img className='img-thumbnail img-fluid' src={post.video} />
          </div>
        )
      ) : (
        <div key='defaultimg'>
          <img src={mainLogo} className='img-thumbnail img-fluid' />
        </div>
      )}
    </section>
  );
}

export default VideoUpload;

import React, { useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Image } from "react-bootstrap";
import useSWR from "swr";
import { fetcher } from "../helpers/axios";
import { getUser } from "../hooks/user.actions";
import { Post } from "../components/posts";
import CreatePost from "../components/posts/CreatePost";
import ProfileCard from "../components/profile/ProfileCard";

function Home() {
  //const [pageIndex, setPageIndex] = useState(0);
  //post/?page=${pageIndex}
  const [postsData, setPostsData] = useState([]);
  const [postsNext, setPostsNext] = useState("/post/");
  let x = false;
  let y = false;
  let next_data = "";

  const posts = useSWR(postsNext, fetcher, {
    refreshInterval: 20000,
  });

  //const posts = useSWR("/post/", fetcher, {
  // refreshInterval: 20000,

  if (typeof posts.data !== "undefined") {
    if (typeof posts.data.results !== "undefined") {
      next_data = postsData.concat(posts.data.results);
      x = true;
      if (posts.data.next) {
        y = true;
      }
    }
  }

  const profiles = useSWR("/user/?limit=5", fetcher);

  const user = getUser();

  if (!user) {
    return <div>Loading!</div>;
  }

  return (
    <Layout>
      <Row className='justify-content-evenly'>
        <Col sm={7}>
          <Row className='border rounded  align-items-center'>
            <Col className='flex-shrink-1'>
              <Image
                src={user.avatar}
                roundedCircle
                width={52}
                height={52}
                className='my-2'
              />
            </Col>
            <Col sm={10} className='flex-grow-1'>
              <CreatePost refresh={posts.mutate} />
            </Col>
          </Row>
          <Row className='my-4'>
            {x &&
              next_data.map((post, index) => (
                <Post key={index} post={post} refresh={posts.mutate} />
              ))}
            {y && (
              <button
                onClick={() => {
                  setPostsData(next_data);
                  setPostsNext(posts.data.next);
                  posts.mutate();
                }}
              >
                Load More
              </button>
            )}
          </Row>
        </Col>
        <Col sm={3} className='border rounded py-4 h-50'>
          <h4 className='font-weight-bold text-center'>Suggested people</h4>
          <div className='d-flex flex-column'>
            {profiles.data &&
              profiles.data.results.map((profile, index) => (
                <ProfileCard key={index} user={profile} />
              ))}
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Home;

import React, { useContext } from "react";
import { format } from "timeago.js";
import { LikeFilled, CommentOutlined, LikeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Image, Card, Dropdown } from "react-bootstrap";
import axiosService from "../../helpers/axios";
import { getUser } from "../../hooks/user.actions";
import UpdatePost from "./UpdatePost";
import { Context } from "../Layout";
import MoreToggleIcon from "../MoreToggleIcon";
import TextWithLineBreaks from "./TextWithLineBreaks";
import { useNavigate } from "react-router-dom";
//import ReactPlayer from "react-player/lazy";

function Post(props) {
  const { post, refresh, isSinglePost } = props;
  const { setToaster } = useContext(Context);
  //order_by const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  const handleNavigateToProfile = (e) => {
    e.preventDefault();
    navigate(`/profile/${post.author.id}/`);
  };
  /*
  setFiles(
    post.video?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )
  );

   const thumbs = files.map((file) => (
    <div className='thumb' key={file.name}>
      <div className='thumbInner'>
        <img
          src={file.preview}
          className='img'
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  )); */

  /*let thumbs = "";

  if (post.video) {
    if (post.video.split(".").pop() === "mp4") {
      thumbs = (
        <div className='video-responsive'>
          <iframe
            className='video-responsive iframe'
            src={post.video}
            allowFullScreen
            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            //onLoad={() => {
            //URL.revokeObjectURL(post.video.url);
            //}}
          />
        </div>
      );
    } else {
      thumbs = (
        <div className='thumb' key={post.video.name}>
          <div className='thumbInner'>
            <img
              src={post.video}
              className='img'
              //onLoad={() => {
              //URL.revokeObjectURL(post.video.url);
              //}}
            />
          </div>
        </div>
      );
    }
  }*/

  const user = getUser();

  const handleLikeClick = (action) => {
    axiosService
      .post(`/post/${post.id}/${action}/`)
      .then(() => {
        refresh();
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = () => {
    axiosService
      .delete(`/post/${post.id}/`)
      .then(() => {
        setToaster({
          type: "warning",
          message: "Post deleted 🚀",
          show: true,
          title: "Post Deleted",
        });
        refresh();
      })
      .catch(() => {
        setToaster({
          type: "danger",
          message: "An error occurred.",
          show: true,
          title: "Post Error",
        });
      });
  };

  return (
    <>
      <Card className='rounded-3 my-4' data-testid='post-test'>
        <Card.Body>
          <Card.Title className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-row'>
              <Image
                src={post.author.avatar}
                roundedCircle
                width={48}
                height={48}
                className='me-2 border border-primary border-2'
              />
              <div className='d-flex flex-column justify-content-start align-self-center mt-2'>
                <p className='fs-6 m-0'>
                  <Link
                    to={"/profile/${post.author.id}/"}
                    onClick={handleNavigateToProfile}
                  >
                    {post.author.name}
                  </Link>
                </p>
                <p className='fs-6 fw-lighter'>
                  <small>{format(post.created)}</small>
                </p>
              </div>
            </div>
            {user.name === post.author.name && (
              <div>
                <Dropdown>
                  <Dropdown.Toggle as={MoreToggleIcon} />
                  <Dropdown.Menu>
                    <UpdatePost post={post} refresh={refresh} />
                    <Dropdown.Item
                      onClick={handleDelete}
                      className='text-danger'
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </Card.Title>
          {/* post.video && (
            <section className='container'>
              <aside className='thumbsContainer'>{thumbs}</aside>
            </section>
          ) */}
          <Card.Text>
            {post.video ? (
              post.video.split(".").pop() === "mp4" ? (
                <video
                  controls
                  muted
                  className='img-thumbnail img-fluid'
                  src={post.video}
                  allowFullScreen
                />
              ) : (
                <div key={post.video.name}>
                  <img className='img-thumbnail img-fluid' src={post.video} />
                </div>
              )
            ) : (
              <div></div>
            )}
            <TextWithLineBreaks text={post.body} />
          </Card.Text>
          <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-row'>
              <LikeFilled
                style={{
                  color: "#fff",
                  backgroundColor: "#0D6EFD",
                  borderRadius: "50%",
                  width: "18px",
                  height: "18px",
                  fontSize: "75%",
                  padding: "2px",
                  margin: "3px",
                }}
              />
              <p className='ms-1 fs-6'>
                <small>{post.likes_count} like</small>
              </p>
            </div>
            {!isSinglePost && (
              <p className='ms-1 fs-6'>
                <small>
                  <Link to={`/post/${post.id}/`}>
                    {post.comments_count} comments
                  </Link>
                </small>
              </p>
            )}
          </div>
        </Card.Body>
        <Card.Footer className='d-flex bg-white w-50 justify-content-between border-0'>
          <div className='d-flex flex-row'>
            <LikeOutlined
              style={{
                width: "24px",
                height: "24px",
                padding: "2px",
                fontSize: "20px",
                color: post.liked ? "#0D6EFD" : "#C4C4C4",
              }}
              onClick={() => {
                if (post.liked) {
                  handleLikeClick("remove_like");
                } else {
                  handleLikeClick("like");
                }
              }}
            />
            <p className='ms-1'>
              <small>Like</small>
            </p>
          </div>
          {!isSinglePost && (
            <div className='d-flex flex-row'>
              <CommentOutlined
                style={{
                  width: "24px",
                  height: "24px",
                  padding: "2px",
                  fontSize: "20px",
                  color: "#C4C4C4",
                }}
              />
              <p className='ms-1 mb-0'>
                <small>Comment</small>
              </p>
            </div>
          )}
        </Card.Footer>
      </Card>
    </>
  );
}

export default Post;

import React, { useContext, useState } from "react";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";
import axiosService from "../../helpers/axios";
import { Context } from "../Layout";
import VideoUpload from "./VideoUpload";

function UpdatePost(props) {
  const { post, refresh } = props;
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    author: post.author.id,
    body: post.body,
  });
  //const [video, setVideo] = useState(post.video);
  //const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [files, setFiles] = useState("");

  const { setToaster } = useContext(Context);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatePostForm = event.currentTarget;

    if (updatePostForm.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    const data = {
      author: form.author,
      body: form.body,
    };

    const formData = new FormData();

    // Checking for null values in the form and removing it.

    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });

    if (files) {
      formData.append("video", files);
    }

    axiosService
      .put(`/post/${post.id}/`, formData)
      .then(() => {
        handleClose();
        setToaster({
          type: "success",
          message: "Post updated 🚀",
          show: true,
          title: "Success!",
        });
        refresh();
      })
      .catch(() => {
        setToaster({
          type: "danger",
          message: "An error occurred.",
          show: true,
          title: "Post Error",
        });
      });
  };

  return (
    <>
      <Dropdown.Item data-testid='show-modal-form' onClick={handleShow}>
        Modify
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='border-0'>
          <Modal.Title>Update Post</Modal.Title>
        </Modal.Header>
        <Modal.Body className='border-0'>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            data-testid='update-post-form'
          >
            <Form.Group className='mb-3 d-flex flex-column'>
              <VideoUpload files={files} setFiles={setFiles} post={post} />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Control
                name='body'
                data-testid='post-body-field'
                value={form.body}
                onChange={(e) => setForm({ ...form, body: e.target.value })}
                as='textarea'
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-testid='update-post-submit'
            variant='primary'
            onClick={handleSubmit}
          >
            Modify
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdatePost;

import React from "react";
import ReactTextFormat from "react-text-format";

function TextWithLineBreaks(props) {
  const textWithBreaks = props.text.split("\n").map((text) => (
    <ReactTextFormat>
      {text}
      <br />
    </ReactTextFormat>
  ));

  return textWithBreaks;
}

export default TextWithLineBreaks;
